export const learningCard = {
  top: {
    title: "学習カード",
    noUnitMessage: "登録されている単元がありません。",
    fetching: "情報取得中"
  },
  modal: {
    title: "振り返り",
    noDataMessage: "表示するデータがありません。",
    noCommentMessage: "まだコメントがありません。",
    dateHeading: "日付",
    contentHeading: "内容",
    unitReflectionHeading: "単元を通しての振り返り",
    selfReflection: "自身の振り返り",
    teacherReflection: "先生からの振り返り",
    aiReflection: "AIの振り返り",
    contentsOf: "{unitName}の内容",
    registerRecord: "記録を登録",
    registerReflection: "振り返りを登録",
    updateReflection: "振り返りを更新",
    generate: "生成する",
    delete: "削除する",
    cancel: "キャンセル",
    cautionForAiContent:
      "※内容が分かりづらい場合、もう一度「生成する」を押してね",
    update: "更新",
    register: "登録",
    dateOfClass: "授業日",
    reflection: "振り返り",
    registerReflectionForUnitName: "{unitName}振り返り登録",
    cautionForAiReflectionGeneration:
      "※ 自身の振り返りをすると、AIの振り返りができるようになります。"
  }
};
