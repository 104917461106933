import { learningCard } from "./learning_card";

export const en = {
  language: "language",
  learningCard
  // テスト用
  // menu: ["Home"],
  // test: "test",
  // object: {
  //   a: "1233"
  // },
  // parse: "welcome to {name}",
  // parses: {
  //   a: "welcome to {name}"
  // },
};
