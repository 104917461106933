export const learningCard = {
  top: {
    title: "Smart Reflection Card",
    noUnitMessage: "There is not unit to show.",
    fetching: "Loading data"
  },
  modal: {
    title: "Reflection",
    noDataMessage: "There is nothing to show here.",
    noCommentMessage: "No comments yet.",
    dateHeading: "Date",
    contentHeading: "Content",
    unitReflectionHeading: "Reflection Through the Unit",
    selfReflection: "Personal Reflection",
    teacherReflection: "Teacher's Reflection",
    aiReflection: "AI's Reflection",
    contentsOf: "Content of {unitName}",
    registerRecord: "Register Record",
    registerReflection: "Register Reflection",
    updateReflection: "Update Reflection",
    generate: "Generate",
    delete: "Delete",
    cancel: "Cancel",
    cautionForAiContent:
      '*If the content is unclear, please press "Generate" again.',
    update: "Update",
    register: "Register",
    dateOfClass: "Date of Class",
    reflection: "Reflection",
    registerReflectionForUnitName: "Register Reflection for {unitName}",
    cautionForAiReflectionGeneration:
      "*You can use AI reflection only if you have registered your own reflection."
  }
};
