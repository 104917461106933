
import { Options, Vue } from "vue-class-component";
import MSelectBox from "@/components/form/MSelectBox.vue";
import store from "@/store";

@Options({
  components: {
    MSelectBox
  }
})
export default class MLangToggleButton extends Vue {
  options = [
    { name: "日本語", value: "ja" },
    { name: "English", value: "en" }
  ];
  get selectedLang() {
    return store.state.lang;
  }

  get translatedValue() {
    return store.state.translatedValue;
  }

  handleChange(lang: string) {
    store.dispatch("setLang", lang);
  }
}
