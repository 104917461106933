
import MBottomNav from "@/components/MBottomNav.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import MLangToggleButton from "@/components/MLangToggleButton.vue";

@Options({
  components: {
    MBottomNav,
    MLangToggleButton
  }
})
export default class MPageTemplate extends Vue {
  get isInLiff(): boolean {
    return store.state.isInLiff;
  }

  get isInMingakuStudentApp(): boolean {
    return store.state.isInMingakuStudentApp;
  }

  get showBottomNav(): boolean {
    if (this.$route.meta.pageType === "other") {
      return false;
    }
    return !this.isInLiff || !this.isInMingakuStudentApp;
  }

  get showLangToggleButton(): boolean {
    return this.$route.meta.showLangToggleButton ?? false;
  }
}
